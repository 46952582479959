<template>
  <div
    ref="root"
    class="text-white min-h-7 bg-gradient-to-r from-[#510F23] to-[#D85753]"
  >
    <div class="container px-2.5 md:px-30 relative bg">
      <div class="text-xs text-center py-1.5" v-html="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{ content: string }>();

const root = ref();

const isVisibleDiscountCompaign = useElementVisibility(root);

const { height } = useElementBounding(root);

watch(height, () => {
  setCustomProperty("--discount-compaign-height", String(`${height.value}px`));
});

watch(isVisibleDiscountCompaign, () => {
  setCustomProperty(
    "--discount-compaign-visible-height",
    String(isVisibleDiscountCompaign.value ? `${height.value}px` : "0px"),
  );
});
</script>
